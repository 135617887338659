import React from "react";

function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="23"
        height="23"
        x="0.5"
        y="0.5"
        stroke="#252525"
        rx="3.5"
      ></rect>
      <path
        stroke="#252525"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.4 6.4l-12 12m0-12l12 12"
      ></path>
    </svg>
  );
}

export default XIcon;
