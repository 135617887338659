import { useAppContext } from '@/core/App/AppContext'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import useProductContext from '@/theme/route/ProductPage/useProductContext'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { notifyOutofStock } from 'src/graphql/mutations/customermutation'
import { ButtonLoader } from '../ButtonLoader'
import useCartContext from '../CartWrapper/useCartContext'
import useAuthContext from '../Contexts/AuthContext'
import { ProductPrice } from './ProductMedia'

const plansSkus = ['plan-silver', 'plan-gold', 'plan-platinum']

export default function AddSubscriptionToCartButton(props) {
   const [{ mitter }] = useAppContext()
   const [cart, , { addToCart, removeItem }] = useCartContext()
   const [user, , { isSignedIn }] = useAuthContext()

   const [product, { getCartItem, selectOption }] = useProductContext()
   const { id, __typename, stock_status } = product
   const [loading, setLoading] = useState(false)
   useEffect(() => {
      // selectOption()
   }, [])
   const cartHasAlreadySubscriptionProduct = cart.items?.find((ci) =>
      plansSkus.includes(ci.product.sku)
   )

   if (__typename == 'MageWorxGiftCards') {
      return null
   }
   if (stock_status != 'IN_STOCK') {
      return (
         <Button
            className="btn-primary btn-block w-100 btn-lg product-btn"
            onClick={() => {
               isSignedIn().then((signedIn) => {
                  if (!signedIn) {
                     mitter.emit(
                        'TOGGLE_LOGIN_MODEL',
                        'Sign in is required for notify stock'
                     )
                  } else {
                     graphqlWebClient
                        .request(notifyOutofStock(id))
                        .then(({ NotifyOutofStock: { message, status } }) => {
                           if (status) {
                              alert(message)
                           }
                        })
                        .catch((err) => {
                           // alert('Something went wrong')
                        })
                  }
               })
            }}
         >
            Notify Me
         </Button>
      )
   }

   return (
      <Button
         onClick={() => {
            if (user.subscription?.status == 'active') {
               alert('You already have active subscription!')
               return false
            }
            const cartItem = getCartItem()
            if (cartHasAlreadySubscriptionProduct) {
               let confirmRplaceMsg = 'You already added subscription plan to cart. Adding this will replace plan in cart.'
               if (!confirm(confirmRplaceMsg)) {
                  return false
               }
               setLoading(true)
               removeItem(cartHasAlreadySubscriptionProduct.uid)
                  .then(() => {
                     addToCart(cartItem, product)
                        .then((d) => {
                           mitter.emit('ShowToaster', { message: `Plan Added to cart` })
                           if (!sessionStorage.getItem(`${cartItem.sku}-upsell`)) {
                              sessionStorage.setItem(`${cartItem.sku}-upsell`, '1')
                              props.isUpsellModal &&
                                 mitter.emit('UpsellModal', cartItem.sku)
                           }
                        })
                        .catch((e) => {
                           mitter.emit('ShowToaster', { message: e })
                        })
                        .finally(() => {
                           setLoading(false)
                        })
                  })
                  .finally(() => {
                     setLoading(false)
                  })

               // return false
            } else {
               setLoading(true)
               addToCart(cartItem, product)
                  .then((d) => {
                     mitter.emit('ShowToaster', { message: `Plan Added to cart` })
                     if (!sessionStorage.getItem(`${cartItem.sku}-upsell`)) {
                        sessionStorage.setItem(`${cartItem.sku}-upsell`, '1')
                        props.isUpsellModal && mitter.emit('UpsellModal', cartItem.sku)
                     }
                  })
                  .catch((e) => {
                     mitter.emit('ShowToaster', { message: e })
                  })
                  .finally(() => {
                     setLoading(false)
                  })
            }
         }}
         className="mt-3 w-100"
         variant={props.variant ?? 'dark'}
      >
         {loading ? <ButtonLoader /> : <span>{props.buttonText}</span>}
      </Button>
   )
}

AddSubscriptionToCartButton.defaultProps = {
   className: '',
   variant: 'dark',
}
