import React, { memo, useEffect, useRef, useState } from 'react'
import ReactDOM, { createPortal } from 'react-dom'

import Button, { Buttons } from '@/theme/components/Button'

import './modal.scss'
import clsx from 'clsx'
import XIcon from 'src/theme/icons/XIcon'

const Portal = ({ children, open }) => {
  const ref = useRef(null)
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    const el = document.createElement('div')
    ref.current = el
    const modalRoot = document.body
    if (modalRoot) {
      modalRoot.appendChild(el)
      setMounted(true)
    }
    return () => {
      if (modalRoot) {
        modalRoot.removeChild(el)
      }
    }
  }, [])

  return ref.current && open ? createPortal(children, ref.current) : null
}

export const ModalComponent = ({
  close,
  isOpen,
  children,
  title = null,
  size = null,
  className = '',
}) => {
  const scrollOffset = useRef(0)
  const lockScroll = React.useCallback(() => {
    document.body.dataset.scrollLock = 'true'
    document.body.style.overflow = 'hidden'
    document.body.style.paddingRight = 'var(--scrollbar-compensation)'

    scrollOffset.current = window.pageYOffset
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollOffset.current}px`
    document.body.style.width = '100%'
  }, [])

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflow = ''
    document.body.style.paddingRight = ''

    document.body.style.position = ''
    document.body.style.top = ``
    document.body.style.width = ''
    window.scrollTo(0, scrollOffset.current)

    delete document.body.dataset.scrollLock
  }, [])

  useEffect(() => {
    if (isOpen) {
      lockScroll()
    } else {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [isOpen])

  return (
    <Portal open={isOpen}>
      <div className="backdrop" onClick={() => close(false)}>
        <div
          className={clsx('modalContainer', size, className)}
          onClick={(e) => e.stopPropagation()}
        >
          <header className="modal-header">
            <div className="modal-header-close">
              <button onClick={() => close(false)}>
                <XIcon />
              </button>
            </div>
          </header>
          <div className="modal-content">
            {title ? (
              <div className="modal-header-title">
                <p>{title}</p>
              </div>
            ) : null}
            {children}
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default ModalComponent
